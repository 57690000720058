const hostApi = window.location.hostname !== 'face.ehailuo.com'
  ? 'https://testapi.ehailuo.com'
  : 'https://api.ehailuo.com';
const hostService = window.location.hostname !== 'face.ehailuo.com'
  ? 'https://testservice.ehailuo.com'
  : 'https://service.ehailuo.com';

export default {
  hostApi,
  hostService,
};
